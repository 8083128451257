<div class="item-barcode">
    <div class="title right5">Barcode:</div>
    <div class="list-item">
        <ng-container *ngFor="let barcode of displayInfo.listBarcodes">
            <div class="item" nz-popover [nzPopoverContent]="barcode?.status ? itemBarcode : ''">
                <span class="right5">{{ barcode.barcode }}</span>
                <nz-tag *ngIf="barcode.status" [nzColor]="getStatusColor(barcode.status)">{{ getTextStatus(barcode.status) }}</nz-tag>
            </div>
        </ng-container>

        <ng-template #itemBarcode>
            <div class="flex">
                <div class="item">
                    <span class="text-label right5">
                        <span>Scanned by</span>
                        <span>{{ displayInfo.driverName ? ' driver:' : ":" }}</span>
                    </span>
                    <a *ngIf="displayInfo.hyperLinkCreatedBy" [routerLink]="[displayInfo.hyperLinkCreatedBy]" target="_blank">
                        {{ displayInfo.driverName || displayInfo.userName }}
                    </a>
                    <span *ngIf="!displayInfo.hyperLinkCreatedBy">
                        No name
                    </span>
                </div>
                <div class="left5 right5">-</div>
                <div class="item">
                    <span>{{ displayInfo.time }} {{ displayInfo.timezone }}</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>