import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import * as pdfjsLib from "pdfjs-dist";
import { ImageUtil } from "@services/image-util";
import { BaseDrawer } from "@app/drawers/base-drawer";
import { Job } from "@wearewarp/types/data-model";
import { AttachedFileUtil } from "@services/attached-file-util";
import { ImageService } from "@services/image.service";
import { WarpId } from "@wearewarp/universal-libs";
import { NzNotificationDataOptions } from "ng-zorro-antd/notification";
import { RoleManager } from "@services/role-manager";
import { OCRService } from "@services/ocr.service";
import to from "await-to-js";
import { ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";
import { AdminDispatchShipmentItem, ResponseAdminDispatchPodUI } from "./interface";
import { UploadFileData } from "@wearewarp/types/rest-api";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Component({
  selector: "[route-pod]",
  templateUrl: "./view.html",
  styleUrls: ["./index.scss"],
})
export class RoutePOD extends BaseDrawer {
  private _taskIds;
  @Input() set taskIds(value) {
    this._taskIds = value;
    if (Utils.isArrayNotEmpty(value)) {
      this.firstTaskId = value[0];
    }
  }
  get taskIds() { return this._taskIds }
  @Input() shipment: any
  @Input() jobId: string
  @Input() stop: any;
  @Input() openUploadForm: boolean = false;
  @Input() refreshData: (resp) => void = () => { };
  public firstTaskId = '';

  public items: ResponseAdminDispatchPodUI[] = [];
  public shipmentItems: AdminDispatchShipmentItem[] = [];
  public isLoading: boolean = true;
  public isUploading: boolean = false;
  public isUploadingPOD: boolean = false;
  public isUploadingBOL: boolean = false;
  public isUploadingSignature: boolean = false;
  public isLoadtenderDetecting: boolean = false;
  public isError: boolean = false;
  private imageDuplicationResult: any = {
    'POD': [],
    'BOL': [],
    'Signature': []
  }
  public job: Job;
  public isFetchingJob: boolean = true;
  public checkedAllPods: boolean = false;
  public indeterminatePods: boolean = false;
  public podIssueShowing: any = null;
  public podIssueItem: any;
  bolExpanded = true;
  signatureExpanded = true;
  podExpanded = true;

  constructor() {
    super();
    this.refreshPodChange = this.refreshPodChange.bind(this);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.onOpenUploadForm();
    await this.fetchJob()
    this.fetchData();
    this.fetchData_idVerification();
  }

  async fetchJob() {
    this.isFetchingJob = true;
    const [error, job] = await to(this.api.GET(`${Const.APIURI_JOBS_V2}/${this.jobId}`).toPromise());
    if (error) {
      console.error("Error fetching job:", error);
      this.isFetchingJob = false;
      return
    }
    this.job = job?.data;
    this.isFetchingJob = false;
  }

  fetchData() {
    if (!Utils.isArrayNotEmpty(this.taskIds)) return;
    this.isLoading = true;
    if (this.isLocationLevel) {
      this.api.GET(`${Const.APIURI_TASKS}/${this.firstTaskId}/pod`).subscribe(
        async (resp) => {
          this.isLoading = false;
          this.items = resp?.data?.list_data || [];
          this.items = await this.formatPod(this.items);
          this.items = await this.preparePodUrlForTask(this.items);
          this.countTaskOfPOD(this.items);
          await this.existIssuePod(this.items);
          await this.compareImages();
        },
        (err) => {
          this.showErr(err, this.getNotificationOptions());
        }
      );
    } else {
      this.podOfShipmentItems();
    }
  }

  public get hasIdVerificationData() { return this.idVerificationData != null }
  public get hasCardDetail() { return this.idVerificationData?.card?.name != null }
  public get idVerificationData_consigneeCardType() { return this.idVerificationData.card.type }
  public get idVerificationData_consigneeName() { return this.idVerificationData.card.name }
  public get idVerificationData_consigneeBirthday() { return this.idVerificationData.card.birthday }
  public get idVerificationData_consigneeCountry() { return this.idVerificationData.card.country }
  public get idVerificationData_consigneeCardExpirationDate() { return this.idVerificationData.card.expirarionDate }

  public get hasManualVerified() { return !this.hasCardDetail && this.idVerificationData.verifyManually }
  public get idVerificationData_consigneeAge() { return this.idVerificationData.verifyManually.age }
  public idVerificationData_images = []

  private idVerificationData;
  private fetchData_idVerification() {
    let taskId;
    if (this.isLocationLevel) {
      taskId = this.firstTaskId;
    } else {
      const taskEntities = this.stop.getTasks();
      const tasks = taskEntities.map(item => item.data);
      taskId = tasks[0].id;
    }
    this.api.GET(`${Const.APIURI_TASKS}/${taskId}/id-verification`).subscribe(
      async resp => {
        this.idVerificationData = resp.data;
        this.idVerificationData_images = await this.formatPod(this.idVerificationData.images);
      }, err => {
        console.error(err);
      }
    );
  }

  idVerification_whenBy() {
    let name = this.getFullName(this.idVerificationData.created);
    if (this.idVerificationData.created.entity == 'drivers') {
      name += ` (driver)`;
    }
    let time = this.displayDateTime(this.idVerificationData.created.when, Const.FORMAT_GUI_DATETIME_V3);
    return `Verified by ${name} at ${time}`;
  }

  isRequiredPod(podType: string): boolean {
    const settings = this.stop?.data?.info?.settings;
    if (!settings) return false;
    switch (podType) {
      case 'POD' :
        return settings.photoRequired;
      case 'BOL' :
        return settings.bolRequired;
      case 'Signature' :
        return settings.signatureRequired;
    }
  }

  get isLocationLevel() {
    if (!this.stop) return true;
    let settingsInfo = this.stop.data?.info?.settings;
    if (!settingsInfo) return false;
    if (!settingsInfo.hasOwnProperty('podAtLocationLevel')) return true;
    return settingsInfo.podAtLocationLevel;
  }

  async podOfShipmentItems() {
    if (!this.stop) return;

    const shipmentEntities = this.stop.getShipments();
    const taskEntities = this.stop.getTasks();
    const shipments = shipmentEntities.map(item => item.data);
    const tasks = taskEntities.map(item => item.data);

    if (shipments.length == 0 || tasks.length == 0) return;

    try {
      await Promise.all(tasks.map(async task => {
        const resp = await this.api.GET(`${Const.APIURI_TASKS}/${task.id}/pod`).toPromise();
        task.pods = resp?.data?.list_data || [];
        task.pods = await this.formatPod(task.pods);
        task.pods = await this.preparePodUrlForTask(task.pods);
        this.countTaskOfPOD(task.pods);
        await this.existIssuePod(task.pods)
      }));

      this.shipmentItems = shipments.map(shipment => {
        const tasksForShipment = tasks.filter(task => task.shipmentId === shipment.id);
        const taskIds = tasksForShipment.map(task => task.id);
        let allPods: ResponseAdminDispatchPodUI[] = [];
        for (let item of tasksForShipment) {
          if (item?.pods?.length) allPods = [...allPods, ...item.pods];
        }
        return <AdminDispatchShipmentItem>{
          _id: shipment._id,
          id: shipment.id,
          taskIds: taskIds,
          pods: allPods,
          code: shipment.code,
          warpId: shipment.warpId
        };
      });
      await this.compareImages();
    } catch (err) {
      this.showErr(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async compareImages() {
    let podUrlByTypes = {
      POD: [],
      BOL: [],
      Signature: []
    };

    await Promise.all(Object.keys(podUrlByTypes).map(async photoType => {
      if (this.isLocationLevel || photoType !== 'POD') {
        const items = this.getItems(photoType);
        const imgs = await Promise.all(items.map(async (item) => {
          return await this.getImageArrayBuffer(item);
        }))
        this.imageDuplicationResult[photoType] = await ImageService.compareImages(imgs);
      } else {
        if (this.shipmentItems.length === 0) return;
        const items = this.shipmentItems.flatMap(item =>
          item.pods.filter(pod => pod.podType === "Photo" || !pod.podType)
            .filter(pod => !pod.isPdf)
            .map(pod => pod)
        );
        const imgs = await Promise.all(items.map(async (item) => {
          return await this.getImageArrayBuffer(item);
        }))
        for (let i = 0; i < this.shipmentItems.length; i++) {
          this.imageDuplicationResult[photoType][this.shipmentItems[i].id] = await ImageService.compareImages(imgs);
        }
      }
    }));
  }

  private async getImageArrayBuffer(item): Promise<ArrayBuffer> {
    let id = item.id || item._id;
    let url = `${Const.APIURI_DOWNLOAD}/${id}`;
    const [err, resp] = await to(this.api.download(url).toPromise());
    let file = new Blob([resp], { type: item.type });
    return await file.arrayBuffer();
  }

  public getDuplicated(itemIndex, type, shipment = null) {
    const compareData = shipment && !this.isLocationLevel
      ? this.imageDuplicationResult?.[type]?.[shipment.id]?.[itemIndex]
      : this.imageDuplicationResult?.[type]?.[itemIndex];
    if (!compareData) return;
    const result = compareData.map((value, index) => ({ index, value }))
      .find(it => it.value > 0.75);
    return result;
  }

  private async preparePodUrlForTask(pods: ResponseAdminDispatchPodUI[]) {
    if (!Utils.isArrayNotEmpty(pods)) {
      return [];
    }
    const firstTaskId = this.firstTaskId;
    for (let i = 0; i < pods.length; i++) {
      let podItem = pods[i];
      if (this.isPdf(podItem)) {
        let url = podItem.url //this.attachedFileUrl(podItem);
        podItem.loadingTask = pdfjsLib.getDocument({
          url,
          // withCredentials: true
        });
        podItem.loadingTask.promise.then(
          function (pdf) {
            pdf.getPage(1).then(function (page) {
              var desiredWidth = 100; // css class attached-pod
              var viewport = page.getViewport({ scale: 1 });
              var scale = desiredWidth / viewport.width;
              var scaledViewport = page.getViewport({ scale: scale });
              var canvas = <HTMLCanvasElement>(
                document.getElementById(`task-${firstTaskId}-pod-${i}`)
              );
              if (!canvas) return;
              var context = canvas.getContext("2d");
              canvas.height = scaledViewport.height;
              canvas.width = scaledViewport.width;
              var renderContext = {
                canvasContext: context,
                viewport: scaledViewport,
              };
              var renderTask = page.render(renderContext);
              renderTask.promise
                .then()
                .catch((e) => console.error("DPF render error ", e));
            });
          },
          function (err) {
            console.error("PDF loading error ", err);
          }
        );
      }
    }
    return pods;
  }

  private countTaskOfPOD(pods: ResponseAdminDispatchPodUI[]) {
    for (let item of pods) {
      const tasks = this.getTasksOfPOD(item);
      item.countTasks = tasks.length;
      item.tasks = tasks;
      item.taskString = tasks.map(task => WarpId.showShipment((<any>task)?.shipment?.warpId)).join(', ');
    }
    return pods;
  }

  private async existIssuePod(pods: ResponseAdminDispatchPodUI[]) {
    for (let item of pods) {
      this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${item._id}&subjectType=pod&type=note`).subscribe(
        (resp) => {
          item.isHasIssue = resp.data.count > 0 ? true : false;
        },
        (err) => {
          this.showErr(err);
        }
      );
    }
    return pods;
  }

  public getTasksOfPOD(podItem: ResponseAdminDispatchPodUI) {
    const tasks = this.job?.tasks || []
    let podExistInTasks = tasks.filter(task => {
      const podArr = task?.podArr || [];
      return podArr.find(pod => pod._id == podItem._id || pod.id == podItem.id)
    });
    return podExistInTasks || [];
  }

  closeDrawer() {
    this.refreshData(true);
    super.closeDrawer();
  }

  async refreshPodChange(){
    await this.fetchJob();
    this.fetchData()
  }


  getHyperLinkWhenBy = (user: ResponseWhenBy_User) => {
    switch (user.entity) {
      case 'users':
      case 'drivers':
      case 'carriers':
      case 'clients':
        return `${Const.routeDashboard}/${user.entity}/${user.id}`;
      default:
        return '';
    }
  }

  public attachedFileUrl(podItem: UploadFileData): string {
    //tạm thời không dùng presigned cho PDF để tránh lỗi CORS khi preview
    return AttachedFileUtil.attachedFileUrl(podItem, true);
  }

  private async formatPod(pods: ResponseAdminDispatchPodUI[]): Promise<ResponseAdminDispatchPodUI[]> {
    return await Promise.all(pods.map(async (item, index) => {
      const url = this.attachedFileUrl(item);
      const response = await this.api.GET(`${url}?no-redirect=1`).toPromise();
      const presignUrl = response?.data?.url || '';
      const result: ResponseAdminDispatchPodUI = {
        ...item,
        index,
        url: presignUrl,
        isPdf: this.isPdf(item),
        // createdAt: item?.insert.when,
        // confirmed: item?.confirmed || {},
      };
      return result;
    }));
  }

  isConfirmPOD(item: ResponseAdminDispatchPodUI) {
    if (item.confirmed?.by && item.confirmed?.when) return true;
    return false;
  }

  canUnconfirmPOD(item: ResponseAdminDispatchPodUI) {
    if(this.isConfirmPOD(item) && RoleManager.userHasRole(this.authUser, RoleManager.accounting)) return true;
    return false;
  }

  onBtnConfirm = async (item: ResponseAdminDispatchPodUI, podType?) => {
    let warningMsg = 'Are you sure to confirm this POD?';
    // if (podType === 'POD' && item?.url) {
    //   // Hiện chỉ áp cho POD
    //   let id = item.id || item._id;
    //   let url = `${Const.APIURI_DOWNLOAD}/${id}`;
    //   const [err, resp] = await to(this.api.download(url).toPromise());
    //   if (!err) {
    //     let blob = new Blob([resp], { type: item.type });
    //     let isLoadTenderDocument: boolean = await this.checkLoadTenderDocument(blob);
    //     if (isLoadTenderDocument) {
    //       warningMsg = 'It seems you want to confirm the Load Tender document. Are you sure you want to confirm it?'
    //     }  
    //   }
    // }
    this.confirmYesNo(warningMsg, async () => {
      let params = {
        taskIds: this.taskIds,
        "uploadPodFileId": item._id
      };
      let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;

      try {
        const resp = await this.api.POST(url, params).toPromise();
        const respData: ResponseAdminDispatchPodUI = resp?.data;

        if (!this.isLocationLevel) {
          this.updatePodConfirmedInShipmentItems(item._id, respData?.confirmed);
        } else {
          this.items[item.index].confirmed = respData?.confirmed;
        }

        this.showSuccess("Your POD has been confirmed successfully.", this.getNotificationOptions());
      } catch (err) {
        this.showErr(err, this.getNotificationOptions());
      }
    });
  };

  onBtnUnConfirm = async (item: ResponseAdminDispatchPodUI) => {
    this.confirmDeletion({
      message: "Are you sure to unconfirm this POD?",
      txtBtnOk: "Unconfirm",
      fnOk: async () => {
        let params = {"id": item._id};

        try {
          let url = `${Const.APIURI_TASKS}/${this.taskIds[0]}/un-confirm-pod`;
          const resp = await this.api.POST(url, params).toPromise();
          const respData: ResponseAdminDispatchPodUI = resp?.data;
          if (!this.isLocationLevel) {
            this.updatePodConfirmedInShipmentItems(item._id, respData?.confirmed);
          } else {
            this.items[item.index].confirmed = respData?.confirmed;
          }
          // this.showSuccess("Your PODs have been unconfirmed successfully.");
        } catch (err) {
          this.showErr(err);
        }
      }
    });
  };

  updatePodConfirmedInShipmentItems = (itemId, podConfirmed) => {
    let indexToUpdate = this.shipmentItems.findIndex((shipmentItem) => shipmentItem.pods.some((pod) => pod._id === itemId));
    if (indexToUpdate !== -1) {
      this.shipmentItems[indexToUpdate].pods.forEach((pod) => {
        if (pod._id === itemId) {
          pod.confirmed = podConfirmed;
        }
      });
    }
  };

  onBtnDelete = (item: ResponseAdminDispatchPodUI, shipment = null) => {
    this.confirmDeletion({
      message: "Are you sure you want to delete this POD?",
      txtBtnOk: this.txtDelete,
      fnOk: async () => {
        let taskIds = shipment ? shipment.taskIds : this.taskIds;
        let params = {
          taskIds: taskIds,
          "uploadPodFileId": item._id
        };
        let url = `${Const.APIV2(Const.APIURI_TASKS)}/deletePodForListTasksOfStop`;

        try {
          const resp = await this.api.POST(url, params).toPromise();

          if (!this.isLocationLevel) {
            if (item.podType == "BOL" || item.podType == "Signature") {
              this.shipmentItems.forEach((shipmentItem) => {
                shipmentItem.pods = shipmentItem.pods.filter(p => p._id !== item._id);
                shipmentItem.pods.forEach(function (pod, index) {
                  pod.index = index;
                });
              });
            } else {
              const index = this.shipmentItems.findIndex(ship => ship.id === shipment.id);
              if (index !== -1) {
                this.shipmentItems[index].pods = this.shipmentItems[index].pods.filter(pod => pod._id !== item._id);
                this.shipmentItems[index].pods.forEach(function (pod, index) {
                  pod.index = index;
                });
              }
            }
          } else {
            this.items = this.items.filter((e) => e._id !== item._id);
            this.items.forEach(function (pod, index) {
              pod.index = index;
            });
          }
          await this.compareImages();
          this.showSuccess("Your POD has been deleted successfully.", this.getNotificationOptions());
        } catch (err) {
          this.showErr(err, this.getNotificationOptions());
        }
      },
    });
  };

  onBtnDownload = (item: ResponseAdminDispatchPodUI) => {
    return this.downloadAttachedFile(<any>item);
  }

  async onFileSelected(files: FileList, podType: string, shipment = null) {
    const count = files.length;
    if (count === 0) {
      this.showErr("No file selected!");
      return;
    }

    this.isUploading = true;
    try {
      if (podType == 'POD') podType = 'Photo';
      const listFile = Array.from(files);
      let listLoadtenderFile: File[] = [];
      let uploadedCnt = 0;
      for (const file of listFile) {
        // if (podType === 'Photo') {
        //   const blob = await ImageUtil.resizeImageIfNeeded(file);
        //   let isLoadTenderDocument = await this.checkLoadTenderDocument(blob);
        //   if (isLoadTenderDocument) {
        //     listLoadtenderFile.push(file);
        //     continue;  
        //   }
        // }
        await this.uploadFile(file, podType, shipment);
        uploadedCnt += 1;
      }
      if (uploadedCnt) {
        this.showSuccess(`Your ${uploadedCnt === 1 ? 'POD has' : 'PODs have'} been added successfully.`, this.getNotificationOptions());
      }
      // if (listLoadtenderFile.length > 0) {
      //   this.handleUploadLoadtenderFiles(listLoadtenderFile, podType, shipment);
      // }
    } catch (e) {
      this.showErr(e, this.getNotificationOptions());
    } finally {
      this.handleUploadCompletion(podType, shipment);
    }
  }

  handleUploadLoadtenderFiles(listLoadtenderFile: File[], podType, shipment = null) {
    let strFilesName = "<ul>";
    for (let file of listLoadtenderFile) {
      strFilesName = strFilesName + `<li>${file.name}`;
    }
    strFilesName = strFilesName + '</ul>'
    let singleFileWarning = 'There is a file that appears to be Load Tender document. Do you want to upload it?';
    let multipleFileWarning = `There are ${listLoadtenderFile.length} files that appear to be Load Tender document. Do you want to upload it?`;
    let msgWarning = `${listLoadtenderFile.length > 1 ? multipleFileWarning : singleFileWarning }${strFilesName}`;
    this.confirmYesNo(msgWarning, async () => {
      try {
        for (let file of listLoadtenderFile) {
          await this.uploadFile(file, podType, shipment);
        }
        this.showSuccess(`Your ${listLoadtenderFile.length > 1 ? 'POD has' : 'PODs have'} been added successfully.`, this.getNotificationOptions());
      } catch (e) {
        this.showErr(e, this.getNotificationOptions());
      } finally {
        this.handleUploadCompletion(podType, shipment);
      }
    })
  }

  private async uploadFile(file: File, podType, shipment) {
    const blob = await ImageUtil.resizeImageIfNeeded(file);
    await this.uploadPOD(blob, blob.name, podType, shipment);
  }

  private async uploadPOD(file: Blob, fileName: string, podType: string, shipment = null) {
    let taskIds = this.taskIds;
    if (!this.isLocationLevel && shipment) {
      taskIds = shipment.taskIds;
    }
    if (shipment) {
      shipment.isUploading = true;
    } else {
      switch (podType) {
        case 'Photo':
          this.isUploadingPOD = true;
          break;
        case 'BOL':
          this.isUploadingBOL = true;
          break;
        case 'Signature':
          this.isUploadingSignature = true;
          break;
      }
    }

    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
      'podType': podType
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", file, fileName);
    const timeout = 5 * 60 * 1000;    // upload có thể file to gây lỗi timeout nên để thời gian là 5 phút

    await this.api.postFormData(apiUrl, formData, {timeout}).toPromise();
  }

  private handleUploadCompletion(podType: string, shipment: any) {
    if (shipment) {
      shipment.isUploading = false;
    } else {
      switch (podType) {
        case 'Photo':
          this.isUploadingPOD = false;
          break;
        case 'BOL':
          this.isUploadingBOL = false;
          break;
        case 'Signature':
          this.isUploadingSignature = false;
          break;
      }
    }
    this.refreshPodChange();
  }

  viewPodItem(item) {
    DialogService.preview([item], 0);
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  onOpenUploadForm() {
    setTimeout(() => {
      if (this.openUploadForm) {
        let element: HTMLElement = document.getElementById("uploadRoutePODInput") as HTMLElement;
        //nếu chưa có element thì đợi thêm 200ms và thử lại
        if (!element) {
          return this.onOpenUploadForm();
        }
        element.click();
      }
    }, 200);
  }

  getNotificationOptions(): NzNotificationDataOptions {
    let options: NzNotificationDataOptions = {
      nzPlacement: 'bottomLeft',
      nzDuration: 1500,
      nzStyle: {
        padding: '12px'
      }
    }
    return options;
  }

  public openNoteIsuse(item) {
    if (this.podIssueShowing?._id == item._id) return this.podIssueShowing = null;
    this.podIssueShowing = item;
    this.podIssueItem = item;
  }

  public onCloseNoteIsuseDrawer() {
    this.podIssueShowing = null;
  }

  async checkLoadTenderDocument(file: File | Blob): Promise<boolean> {
    if (file instanceof File || file instanceof Blob) {
      // Hiện chỉ xử lí cho file ảnh
      if (!file.type.includes("image/")) {
        return false;
      }
    }
    this.isLoadtenderDetecting = true;
    let keysLoadTenderDocumentError: any[] = await OCRService.checkLoadTenderDocument(file);
    this.isLoadtenderDetecting = false;
    if (Utils.isArrayNotEmpty(keysLoadTenderDocumentError)) {
      return true;
    }
    return false;
  }
  
  getPodTypeBOLAndSignature(): any[] {
    if (!this.isLocationLevel) {
      return this.shipmentItems.flatMap(input =>
        input.pods.filter(pod => pod.podType === "BOL" || pod.podType === "Signature")
      );
    } else {
      return this.items;
    }
  }

  getItems(podType: string) {
    let pods = this.getPodTypeBOLAndSignature();
    const arr = this.filterUniquePods(pods.filter(pod => pod.podType === podType || (podType === 'POD' && (pod.podType === 'Photo' || !pod.podType))));
    return arr;
  }

  getPodTypePhotoOfShipmentLevel(pods) {
    var filteredPods = pods.filter(function (pod) {
      return pod.podType !== "BOL" && pod.podType !== "Signature";
    });
    filteredPods.forEach(function (pod, index) {
      pod.index = index;
    });
    return filteredPods;
  }

  getImagesOfShipmentLevel(pods) {
    let items = this.getPodTypePhotoOfShipmentLevel(pods);
    return this.filterUniquePods(items.filter(item => !item.isPdf));
  }

  getImages(podType: string) {
    let pods = this.getPodTypeBOLAndSignature();
    return this.filterUniquePods(pods.filter(pod => (pod.podType === podType || (podType === 'POD' && (pod.podType === 'Photo' || !pod.podType))) && !pod.isPdf));
  }

  private filterUniquePods(pods: any[]) {
    const uniquePods = new Map();
    pods.forEach(pod => {
      uniquePods.set(pod._id, pod);
    });
    return Array.from(uniquePods.values());
  }

  hasDataPodShipmentLevel(pods): boolean {
    const isPhotoOrNoTypePod = pod => !pod.podType || pod.podType === 'Photo';
    return pods.some(isPhotoOrNoTypePod);
  }

  shouldExpand(podType: string) {
    switch (podType) {
      case 'BOL':
        return this.bolExpanded;
      case 'Signature':
        return this.signatureExpanded;
      case 'POD':
        return this.isLocationLevel && this.podExpanded;
      case 'Shipment':
        return !this.isLocationLevel && this.podExpanded;
    }
  }

  toggleCollapse(podType: string) {
    switch (podType) {
      case 'BOL':
        this.bolExpanded = !this.bolExpanded;
        break;
      case 'POD':
        this.podExpanded = !this.podExpanded;
        break;
      case 'Signature':
        this.signatureExpanded = !this.signatureExpanded;
        break;
    }
  }

  shouldExpandShipment(ship) {
    return ship.shouldExpand ?? true;
  }

  toggleCollapseShipment(ship) {
    ship.shouldExpand = !ship.shouldExpand ?? true;
  }

  shouldDisplay(podType: string) {
    return podType == 'POD' || this.getItems(podType).length > 0 || this.isRequiredPod(podType);
  }

  shouldDisplayShipment(shipment) {
    if(!this.shipment?.id) return true;
    return shipment.id == this.shipment.id;
  }
}
