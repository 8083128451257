import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@abstract/BaseComponent";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
@Component({
  selector: "[item-barcode]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class ItemBarcode extends BaseComponent {

  private mapBarcodeScanStatus = {
    notScanned: 'NOT_SCANNED', // item pending to be scanned (at pickup / at dropoff)
    scanned: 'SCANNED', // barcode scanning & checkin was successful
    manuallyScanned: 'MANUALLY_SCANNED', // Driver was not able to scan the barcode due to barcode issue / device issue, but checkin is successful when driver entered the barcode string manually using device keypad.
    damaged: 'DAMAGED', // Item box / item is damaged and not in a position to pick / drop.
    notFound: 'NOT_FOUND', // Item not found during pickup.
    missing: 'MISSING', // Item is missing during dropoff; but pickup checkin was successful.
    error: 'error', // Device / system error.
    pickupFailed: 'PICKUP_FAILED', // Report missing in PU
    deliverFailed: 'DELIVER_FAILED' // Report missing in DO
  }

  @Input() itemInfo: any;
  @Input() timezone: any;
  @Input() barcodes: string[] = [];
  
  public displayInfo: any = {};

  constructor(protected activatedRoute: ActivatedRoute, protected dispatchService: DispatchService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.displayInfo = {
      time: this.getDisplayTime(),
      listBarcodes: this.getListBarcodes(),
      userName: this.getFullName(this.itemInfo?.userInfo),
      driverName: this.getDriverName(this.itemInfo?.driverInfo),
      hyperLinkCreatedBy: this.getHyperLinkCreatedBy(),
      timezone: this.getDisplayTimezone()
    }
  }

  private getDisplayTime() {
    return DateUtil.displayLocalTime(this.itemInfo?.insert?.when, {
      timezone: this.timezone,
      format: "MM/DD/YY h:mm A",
    })
  }

  private getDisplayTimezone(){
    return DateUtil.timezoneStandardToUsShort(this.timezone)
  }

  private getListBarcodes() {
    let listBarcodes = [];
    const barcodeScaned = this.itemInfo?.itemInfo?.items?.map(it => it.barcodes).flat() || [];
    const check = barcodeScaned.find(it => this.barcodes.includes(it.barcode));

    if (!Utils.isArrayNotEmpty(barcodeScaned) || !check) return this.barcodes.map(it => { return { barcode: it } });
    for (let item of this.itemInfo?.itemInfo?.items) {
      if (!item.barcodes) continue;
      for (let barcode of item.barcodes) {
        let exist = listBarcodes.find(bc => barcode.barcode == bc.barcode);
        if (!exist && (this.barcodes || []).includes(barcode.barcode)) listBarcodes.push(barcode);
      }
    }
    return listBarcodes;
  }

  getStatusColor(status) {
    switch(status) {
      case this.mapBarcodeScanStatus.notScanned:
        return 'grey';
      case this.mapBarcodeScanStatus.manuallyScanned:
      case this.mapBarcodeScanStatus.scanned:
      case "succeeded":
        return 'green';
      case this.mapBarcodeScanStatus.damaged:
      case this.mapBarcodeScanStatus.notFound:
      case this.mapBarcodeScanStatus.missing:
      case this.mapBarcodeScanStatus.error:
        return 'red';
      default: 
        return 'grey';
    }
  }

  getTextStatus(status): string {
    return this.mapBarcodeScanStatus[status] || status;
  }

  private getHyperLinkCreatedBy() {
    if(this.itemInfo?.driverInfo) return `${Const.routeAdminDriverList}/${this.itemInfo.driverInfo.id}`;
    if(this.itemInfo?.userInfo) return `${Const.routeAdminUserList}/${this.itemInfo.userInfo._id}`;
    return null;
  }

}
